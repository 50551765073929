import { Avatar, Button, ButtonGroup, FormControl, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uniq from 'lodash.uniq';
import moment from 'moment';
import { Spinner } from '../../components/Spinner';
import { hideCreateTournamentDialog, listTournaments, showCreateTournamentDialog } from './tournamentsSlice';
import { dateText } from './tournamentUtils';

const TournamentsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAcademies = useSelector(state => state.academies.userAcademies);
  const listTournamentsStatus = useSelector(state => state.tournaments.listTournamentsStatus);
  const tournaments = useSelector(state => state.tournaments.tournaments)
  const createTournamentStatus = useSelector(state => state.tournaments.createTournamentStatus);

  let [filterTournamentsYear, setFilterTournamentsYear] = useState('upcoming');
  let [filterTournamentsAcademy, setFilterTournamentsAcademy] = useState('_');

  useEffect(() => {
    if (listTournamentsStatus === 'idle') {
      dispatch(listTournaments());
    }
  }, [listTournamentsStatus, dispatch]);

  useEffect(() => {
    if (createTournamentStatus === 'succeeded') {
      dispatch(hideCreateTournamentDialog());
    }
  }, [createTournamentStatus, dispatch]);

  const handleFilterTournamentYearsChange = (e) => {
    setFilterTournamentsYear(e.target.value);
  };

  const handleFilterTournamentAcademyChange = (e) => {
    setFilterTournamentsAcademy(e.target.value);
  };

  const handleCreateTournamentClick = ({ tournamentToReplicate } = {}) => {
    dispatch(showCreateTournamentDialog({ tournamentToReplicate }));
  };

  const tournamentYears = uniq((tournaments || [])
    .map(({ startDate }) => moment(startDate).format('YYYY')))
    .sort()
    .reverse();

  const filteredTournaments = (tournaments || []).filter(({ academyId, startDate, endDate }) => {
    if (filterTournamentsYear === 'upcoming') {
      const tournamentAfterDate = moment()
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)
        .toDate();
      if (moment(endDate).toDate() < tournamentAfterDate) {
        return false;
      }
    } else {
      const tournamentAfterDate = moment()
        .set('year', parseInt(filterTournamentsYear))
        .set('month', 0)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0);
      const tournamentBeforeDate = moment()
        .set('year', parseInt(filterTournamentsYear))
        .set('month', 11)
        .set('day', 31)
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .set('millisecond', 999);
      if (moment(startDate).toDate() < tournamentAfterDate) {
        return false;
      }
      if (moment(startDate).toDate() > tournamentBeforeDate) {
        return false;
      }
    }
    if (filterTournamentsAcademy !== '_' && academyId !== filterTournamentsAcademy) {
      return false;
    }
    return true;
  });

  const tournamentListItem = (tournament) => {
    const manageLink = `/tournaments/${tournament.academyId}/${tournament.tournamentId}/manage`;
    return (
      <ListItem key={tournament.tournamentId} secondaryAction={
        <ButtonGroup variant="text" edge="end">
          <Button href={manageLink} onClick={() => navigate(manageLink)}>Manage</Button>
          <Button onClick={() => handleCreateTournamentClick({ tournamentToReplicate: tournament })}>Replicate</Button>
        </ButtonGroup>
      }>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={tournament.name} secondary={dateText(tournament)} />
      </ListItem>
    );
  }

  const academiesById = {};
  userAcademies.forEach((academy) => {
    academiesById[academy.academyId] = academy;
  });
  const tournamentsLabel = [
    `${filterTournamentsYear === 'upcoming' ? 'Upcoming' : filterTournamentsYear} Tournaments`,
    `${filterTournamentsAcademy === '_' ? '' : ` for ${academiesById[filterTournamentsAcademy].academyName}`}`,
    `${tournaments ? ` (${filteredTournaments.length})` : ''}`
  ].join('');

  return (
    <div className="m-4">
      <div className="mb-2">
        <FormControl>
          <Select
            value={filterTournamentsYear}
            onChange={handleFilterTournamentYearsChange}
          >
            <MenuItem value="upcoming">Upcoming Tournaments</MenuItem>
            {tournamentYears.map((year) => (
              <MenuItem key={year} value={year}>{`${year} Tournaments`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {userAcademies.length > 1 && <FormControl>
          <Select
            value={filterTournamentsAcademy}
            onChange={handleFilterTournamentAcademyChange}
          >
            <MenuItem value="_">All Academies</MenuItem>
            {userAcademies.map(({ academyId, academyName }) => (
              <MenuItem key={academyId} value={academyId}>{academyName}</MenuItem>
            ))}
          </Select>
        </FormControl>}
      </div>
      <Typography variant="h6">
        {tournamentsLabel}
      </Typography>            
      <Spinner thunks={listTournaments} errorMessage={'Failed to fetch upcoming tournaments'} componentFn={() => (
        <React.Fragment>            
          <List className="sm:w-2/3 lg:w-1/2">
            {filteredTournaments.map(tournamentListItem)}
          </List>
          {userAcademies && userAcademies.length !== 0 && (<Button variant="outlined" onClick={() => handleCreateTournamentClick()}>Create Tournament</Button>)}
        </React.Fragment>
      )} />
    </div>
  )
}

export default TournamentsPage;